<template>
    <el-dialog
        :title="$t('settings.add_role')"
        :visible.sync="visible"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <div v-loading="$wait.is('adding')" class="flex justify-center my-5 px-12">
            <el-form ref="form" :model="formData" label-position="top" :rules="rules" class="w-full">
                <el-form-item :label="$t('settings.role_name')" prop="name" class="w-full">
                    <el-input v-model="formData.name" />
                </el-form-item>
            </el-form>
        </div>

        <span slot="footer">
            <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('adding')" @click="add">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            formData: {
                name: '',
            },
            rules: {
                name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async add() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            try {
                this.$wait.start('adding');
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/roles`, {
                    name: this.formData.name,
                });
                this.$emit('refresh');
            } finally {
                this.$wait.end('adding');
                this.closeModal();
            }
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.formData.name = '';
            this.$emit('close');
        },
    },
};
</script>
